import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Grid, Header, Segment, Button, Container, Divider, Popup } from "semantic-ui-react";
import { Form, Field } from "react-final-form";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";

import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import i18n from "modules/i18n/i18nConfig";
import { identity } from "modules/common/utils/form";
import { regexEmail } from "modules/common/utils";
import { useAddUserMutation, useGetUserQuery, useGetUsersQuery, useUpdateUserMutation } from "../userService";
import { useGetSitesQuery } from "modules/site/siteService";
import history_app from "history_app";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import { InputAdapter, ToggleAdapter } from "modules/common/components/form";
import UserRights from "./UserRights";
import PasswordChange from "./PasswordChange";
import OtpUser from "./OtpUser";
import LoginHistoryModal from "./LoginHistoryModal";

const User = () => {
    const { id, mode } = useParams();
    const { org, auth, notification } = useSelector((state) => state);
    const [fromAdd, setFromAdd] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);

    const users = useGetUsersQuery({ org: org.current }, { skip: !org.current });
    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const user = useGetUserQuery({ org: org.current, user_id: parseInt(id) }, { skip: !org.current || id === undefined });

    const can_add_user = notification.srv_status.db_status === "rw" && auth.rights?.is_admin && mode === "add" && id === undefined;
    const can_change_email =
        notification.srv_status.db_status === "rw" &&
        _.includes(["change", "add"], mode) &&
        auth.rights?.is_admin &&
        user.data?.id !== auth.user.user_id;
    const can_change_user =
        notification.srv_status.db_status === "rw" &&
        mode === "change" &&
        _.isFinite(parseInt(id)) &&
        (user.data?.id === auth.user.user_id || auth.rights?.is_admin);
    const can_change_rights = notification.srv_status.db_status === "rw" && auth.rights?.is_admin && mode === "change";

    const [updateUser, update] = useUpdateUserMutation();
    const [addUser, add] = useAddUserMutation();

    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`successful update user`), toast_options);
        }
        if (update.isError) {
            toast.error(i18n._(t`cannot update user`), toast_options_err);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isSuccess, update.isError, update.isLoading]);

    useEffect(() => {
        if (can_add_user) {
            if (add.isLoading) {
                toast.info(i18n._(t`send request to server`), toast_options);
            }
            if (add.isSuccess) {
                toast.success(i18n._(t`successful add user`), toast_options);
                history_app.push(`/users/${add.data.id}/change`);
                setFromAdd(true);
            }
            if (add.isError) {
                toast.error(i18n._(t`cannot add user`), toast_options_err);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [can_add_user, add.isSuccess, add.isError, add.isLoading]);

    let err_list = [users.isError, sites.isError];
    if (id !== undefined) {
        err_list = [...err_list, user.isError];
    }
    let status_list = [users.isSuccess, sites.isSuccess];
    if (id !== undefined) {
        status_list = [...status_list, user.isSuccess];
    }

    const submitForm = async (formData) => {
        if (can_add_user) {
            addUser({ org: org.current, data: formData });
        } else if (can_change_user) {
            const data = _.omit(formData, ["resetOtp"]);
            const resetOtp = formData?.resetOtp ?? false;
            //override email with initial email.
            //prevent user modification of email
            updateUser({ org: org.current, data: { ...data, has_otp: resetOtp ? false : data.has_otp } });
        }
    };

    const initialValues = useMemo(() => {
        const defaultValues = { email: "", is_admin: false, resetOtp: false };

        return {
            ...defaultValues,
            ...user.data
        };
    }, [user.data]);

    return (
        <Segment attached>
            {openPassword && <PasswordChange item={user.data} setPasswordChange={setOpenPassword} />}
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader no-print">
                    <Grid.Column width={2}>
                        <Back target={fromAdd ? "/users" : undefined} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {mode === "add" && <Trans>Invite user</Trans>}
                            {mode === "change" && user.data?.id !== auth.user.user_id && <Trans>Change user</Trans>}
                            {mode === "change" && user.data?.id === auth.user.user_id && <Trans>account</Trans>}
                            {mode === "view" && <Trans>View user</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                {(() => {
                    if (mode === "add" && !can_add_user) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`You are not allowed to add users`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                    if (_.some(err_list)) {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    } else if (_.every(status_list)) {
                        return (
                            <>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Container>
                                            <Form
                                                onSubmit={submitForm}
                                                initialValues={initialValues}
                                                render={({ handleSubmit, submitting, pristine, invalid }) => {
                                                    return (
                                                        <form onSubmit={handleSubmit} className="ui form">
                                                            <Grid centered>
                                                                <Grid.Row>
                                                                    <Grid.Column width={8}>
                                                                        <Field
                                                                            name="is_admin"
                                                                            label={i18n._(t`is_admin`)}
                                                                            labelposition="right"
                                                                            component={ToggleAdapter}
                                                                            //owner can't change his is_admin flag
                                                                            disabled={user.data?.id === auth.user?.user_id && mode !== "add"}
                                                                        />
                                                                    </Grid.Column>

                                                                    <Grid.Column width={8} textAlign="right">
                                                                        <Button.Group>
                                                                            {notification.srv_status.db_status === "rw" &&
                                                                                user.data?.id === auth.user.user_id && (
                                                                                    <Popup
                                                                                        trigger={
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="lock"
                                                                                                onClick={(e) => setOpenPassword(true)}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        <Popup.Content>
                                                                                            <Trans>Change password</Trans>
                                                                                        </Popup.Content>
                                                                                    </Popup>
                                                                                )}
                                                                            {notification.srv_status.db_status === "rw" &&
                                                                                user.data?.id === auth.user.user_id &&
                                                                                user.data?.has_otp === false && <OtpUser item={user.data} />}
                                                                            <LoginHistoryModal user_id={auth.user?.user_id} />
                                                                        </Button.Group>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row>
                                                                    <Grid.Column width={16}>
                                                                        <Field
                                                                            name="email"
                                                                            placeholder={i18n._(t`set email here`)}
                                                                            label={i18n._(t`email`)}
                                                                            isRequired={true}
                                                                            parse={identity}
                                                                            component={InputAdapter}
                                                                            validate={(value) => {
                                                                                if (mode === "view") return undefined;
                                                                                if (mode === "add" && value === "") {
                                                                                    return <Trans>Email is required</Trans>;
                                                                                }
                                                                                if (!regexEmail.test(value)) {
                                                                                    return <Trans>Adresse électronique invalide</Trans>;
                                                                                }
                                                                                const existing_email = _.chain(users.data)
                                                                                    .filter((user) => {
                                                                                        return user.id !== parseInt(id);
                                                                                    })
                                                                                    .find({ email: value })
                                                                                    .value();
                                                                                if (existing_email) {
                                                                                    return (
                                                                                        <Trans>
                                                                                            A user with this email already in your organization
                                                                                        </Trans>
                                                                                    );
                                                                                }
                                                                                return undefined;
                                                                            }}
                                                                            disabled={!can_change_email}
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                {mode !== "add" && (
                                                                    <>
                                                                        <Grid.Row>
                                                                            <Grid.Column width={16}>
                                                                                <Field
                                                                                    name="first_name"
                                                                                    placeholder={i18n._(t`set first name here`)}
                                                                                    label={i18n._(t`first_name`)}
                                                                                    component={InputAdapter}
                                                                                    parse={identity}
                                                                                    disabled={!can_change_user}
                                                                                />
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                        <Grid.Row>
                                                                            <Grid.Column width={16}>
                                                                                <Field
                                                                                    name="last_name"
                                                                                    placeholder={i18n._(t`set last name here`)}
                                                                                    label={i18n._(t`last_name`)}
                                                                                    component={InputAdapter}
                                                                                    parse={identity}
                                                                                    disabled={!can_change_user}
                                                                                />
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                        {user.data?.has_otp === true && (
                                                                            <Grid.Row>
                                                                                <Grid.Column width={16}>
                                                                                    <Divider />
                                                                                    <Field
                                                                                        name="resetOtp"
                                                                                        label={i18n._(t`Disable 2FA ?`)}
                                                                                        labelposition="right"
                                                                                        toggle={false}
                                                                                        component={ToggleAdapter}
                                                                                    />
                                                                                </Grid.Column>
                                                                            </Grid.Row>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <Grid.Row>
                                                                    <Grid.Column width={16}>
                                                                        {(can_add_user || can_change_user) && (
                                                                            <>
                                                                                <Divider />
                                                                                <Button
                                                                                    type="submit"
                                                                                    content={i18n._(t`validate`)}
                                                                                    disabled={submitting || pristine || invalid}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </form>
                                                    );
                                                }}
                                            />
                                        </Container>
                                    </Grid.Column>
                                </Grid.Row>
                                {initialValues.is_admin === false && mode !== "add" && (
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Container>
                                                <UserRights sites={sites.data} can_change={can_change_rights} org={org} id={id} />
                                            </Container>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </>
                        );
                    } else {
                        return (
                            <Grid.Row>
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            </Grid.Row>
                        );
                    }
                })()}
            </Grid>
        </Segment>
    );
};

export default User;
