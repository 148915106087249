import React, { useState } from "react";
import { Trans } from "@lingui/macro";

import { Popup, Icon } from "semantic-ui-react";

const TextEnhanced = (props) => {
    const { text, style } = props;
    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => setShowMore(!showMore);

    const firstLine = text.split("\n")[0];
    const firstLineLength = firstLine.length;
    const numberOfAllowedChars = 100;

    const renderPopup = (isOpen) => {
        return (
            <Popup
                trigger={
                    <Icon
                        style={{ cursor: "pointer" }}
                        size={"large"}
                        color="grey"
                        name={`arrow alternate circle ${isOpen ? "left" : "right"} outline`}
                        onClick={toggleShowMore}
                    />
                }
            >
                <Popup.Content>
                    <Trans>{isOpen ? "Hide message" : "View full message"}</Trans>
                </Popup.Content>
            </Popup>
        );
    };

    if (firstLineLength > numberOfAllowedChars) {
        const firstPart = text.substring(0, numberOfAllowedChars);
        const secondPart = text.substring(numberOfAllowedChars);
        return (
            <>
                <span style={{ whiteSpace: "pre-line" }}>{firstPart}</span>
                {showMore && (
                    <>
                        <br />
                        <span style={{ whiteSpace: "pre-line" }}>{secondPart}</span>
                        {renderPopup(true)}
                    </>
                )}
                {!showMore && renderPopup(false)}
            </>
        );
    } else if (text.includes("\n")) {
        const parts = text.split("\n");
        const firstPart = parts[0];
        const secondPart = parts.slice(1).join("\n");

        return (
            <>
                <span style={{ whiteSpace: "pre-line" }}>{firstPart}</span>
                {showMore && (
                    <>
                        <br />
                        <span style={{ whiteSpace: "pre-line" }}>{secondPart}</span>
                        {renderPopup(true)}
                    </>
                )}
                {!showMore && renderPopup(false)}
            </>
        );
    } else {
        return <span style={style}>{text}</span>;
    }
};

export default TextEnhanced;
