import { defaultApi, refreshTimestampUrlParam } from "apis/PowerAdapt";
import _ from "lodash";

export const alertEventApi = defaultApi.injectEndpoints({
    reducerPath: "apiAlertEvent",
    endpoints: (build) => ({
        getTriggeredAlerts: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `triggeredalerts?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                /* response from server, list not a dict:
                    [
                        0:id, 
                        1:alert_name, 
                        2:start_change_state, 
                        3:notified_date_start, 
                        4:triggered_values_start,
                        5:triggered_formula_start, 
                        6:triggered_date_start, 
                        7:readable_condition_start,
                        8:end_change_state, 
                        9:notified_date_end, 
                        10:triggered_values_end,
                        11:triggered_formula_end, 
                        12:triggered_date_end, 
                        13:readable_condition_end
                    ]
                */
                return response;
            },
            providesTags: (result, error, arg) => {
                if (!error) return [{ type: "TriggeredAlerts", id: arg.org.id }];
                return [];
            }
        }),
        getEventsAlerts: build.query({
            keepUnusedDataFor: 600,
            query: ({ org, start, end }) => {
                const current_org = _.get(org, "name", null);
                return `eventstates?org=${current_org}&start=${start}&end=${end}&${refreshTimestampUrlParam()}`;
            },
            transformResponse: (response) => {
                return response;
            },
            providesTags: (result, error, arg) => {
                if (!error) return [{ type: "EventAlerts", id: arg.org.id }];
                return [];
            }
        })
    })
});

export const { useGetTriggeredAlertsQuery, useGetEventsAlertsQuery } = alertEventApi;
