import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";
import { Grid, Segment, Header, Input, Dropdown } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { severity_options, contentTtype_options } from "../utils/index";
import { roundedDate } from "modules/time/utils";
import { useGetActivityLogQuery } from "../activityLogService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import TextEnhanced from "./TextEnhanced";
import TimePanel from "modules/common/components/TimePanel";

const ActivityLog = () => {
    const now = moment();

    const { id_type } = useParams();

    const [localSearchName, setLocalSearchName] = useState("");
    const [selectedSeverity, setSelectedSeverity] = useState(severity_options[0].value);
    const [selectedImportType, setSelectedImportType] = useState(null);
    const [time, setTime] = useState({
        start: roundedDate(now.clone().subtract(3, "months").startOf("days"), 10),
        end: roundedDate(now.clone().startOf("minute"), 10)
    });

    const { org } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);

    const activityLog = useGetActivityLogQuery(
        {
            org: org.current,
            id_type: selectedImportType,
            severity: selectedSeverity,
            start: time.start.clone().startOf("d").toISOString(),
            end: time.end.clone().startOf("d").add(1, "d").toISOString()
        },
        { skip: !org.current || !_.isFinite(selectedSeverity) || selectedImportType === null }
    );

    useEffect(() => {
        if (id_type) {
            setSelectedImportType(contentTtype_options.find((item) => item.value === parseInt(id_type)).value);
        } else {
            setSelectedImportType(contentTtype_options[0].value);
        }
    }, [id_type]);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "date", label: i18n._(t`date`), textAlign: "left" },
        { id: "message", label: i18n._(t`Message`), textAlign: "left" },
        { id: "warnings", label: i18n._(t`Warnings`), textAlign: "center" },
        { id: "errors", label: i18n._(t`Errors`), textAlign: "center" }
    ];

    const logs_list = _.chain(activityLog?.data)
        .reduce((res, item) => {
            if (localSearchName === "") {
                res.push(item);
            } else if (_.includes(item.object_name.toLowerCase(), localSearchName.toLowerCase())) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "nowrap" };
            const tmst = moment(item.activity_date, moment.ISO_8601, true);
            res.push({
                id: { render: null, value: item.pk, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "object_name", "-")}</span>,
                    value: _.get(item, "object_name", "-"),
                    textAlign: "left",
                    negative: item.errors > 0,
                    warning: item.warnings > 0,
                    datatype: "string"
                },
                date: {
                    render: <span style={customStyle}>{tmst.isValid() ? tmst.locale(current_lng).format("LLL") : "-"}</span>,
                    value: tmst.isValid() ? tmst : null,
                    textAlign: "left",
                    negative: item.errors > 0,
                    warning: item.warnings > 0,
                    datatype: "date"
                },
                message: {
                    render: (
                        <span>
                            <TextEnhanced style={customStyle} text={_.get(item, "message", "-")} />
                        </span>
                    ),
                    value: _.get(item, "message", "-"),
                    textAlign: "left",
                    negative: item.errors > 0,
                    warning: item.warnings > 0,
                    datatype: "string"
                },
                warnings: {
                    render: <span style={customStyle}>{_.get(item, "warnings", "-")}</span>,
                    value: _.get(item, "warnings", "-"),
                    textAlign: "center",
                    negative: item.errors > 0,
                    warning: item.warnings > 0,
                    datatype: null
                },
                errors: {
                    render: <span style={customStyle}>{_.get(item, "errors", "-")}</span>,
                    value: _.get(item, "errors", "-"),
                    textAlign: "center",
                    negative: item.errors > 0,
                    warning: item.warnings > 0,
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Activity logs</Trans>&nbsp;
                            {selectedImportType === 1 && <Trans>from files imports</Trans>}
                            {selectedImportType === 2 && <Trans>from schedule exports</Trans>}
                            {selectedImportType === 3 && <Trans>from advanced reports</Trans>}
                            {selectedImportType === 4 && <Trans>from Api key</Trans>}
                            {selectedImportType === 5 && <Trans>from Api imports</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <TimePanel
                            hide_plage={true}
                            time={time}
                            timeFormat={false}
                            start_date_limit={now.clone().startOf("d").subtract(1, "years")}
                            accordion={true}
                            action={({ start, end }) => {
                                setTime({ start: moment(start), end: moment(end) });
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        <Grid celled>
                            <Grid.Column mobile={16} tablet={3} computer={3}>
                                <Input
                                    fluid
                                    icon="search"
                                    placeholder={i18n._(t`search by name`)}
                                    onChange={(e, { value }) => {
                                        setLocalSearchName(value);
                                    }}
                                    value={localSearchName}
                                />
                            </Grid.Column>

                            <Grid.Column mobile={16} tablet={6} computer={4}>
                                <Dropdown
                                    fluid
                                    button
                                    className="icon"
                                    labeled
                                    icon="newspaper"
                                    options={_.map(contentTtype_options, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                    placeholder={i18n._(t`select activity type`)}
                                    selection
                                    onChange={(e, { value }) => {
                                        setSelectedImportType(value);
                                    }}
                                    value={selectedImportType}
                                />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={6} computer={4}>
                                <Dropdown
                                    fluid
                                    button
                                    className="icon"
                                    labeled
                                    icon="bullhorn"
                                    options={_.map(severity_options, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                                    placeholder={i18n._(t`select status`)}
                                    selection
                                    onChange={(e, { value }) => {
                                        setSelectedSeverity(value);
                                    }}
                                    value={selectedSeverity}
                                />
                            </Grid.Column>

                            {(() => {
                                if (activityLog.isError) {
                                    return (
                                        <Grid.Row>
                                            <Grid.Column width={15}>
                                                <MessageDisplay
                                                    message={i18n._(t`error loading data`)}
                                                    level="error"
                                                    iconName="warning circle"
                                                    isLoading={false}
                                                    attached={false}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    );
                                }
                                if (activityLog.isSuccess) {
                                    return (
                                        <Grid.Column width={16}>
                                            <TableEnhanced
                                                headCells={headCells}
                                                rows={logs_list}
                                                order="desc"
                                                orderBy="date"
                                                textItemPerPages={i18n._(t`items per page`)}
                                            />
                                        </Grid.Column>
                                    );
                                }
                                return (
                                    <Grid.Row>
                                        <Grid.Column width={15}>
                                            <MessageDisplay
                                                message={i18n._(t`loading data`)}
                                                level="info"
                                                iconName="circle notched"
                                                isLoading={true}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                );
                            })()}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default ActivityLog;
