import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t } from "@lingui/macro";
import { Button, Dimmer, Grid } from "semantic-ui-react";
import { useInView } from "react-intersection-observer";

import i18n from "modules/i18n/i18nConfig";
import { mt_type_blacklist } from "../utils";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";

import { Media } from "App";
import Infos from "./widgets/Infos";
import Details from "./widgets/Details";
import DispoInfo from "./widgets/DispoInfo";
import DashboardWidgets from "./DashboardWidgets";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Name from "./widgets/Name";

const DashboardRow = (props) => {
    const { equipment, lastActivity, isScrolling } = props;
    const org = useSelector((state) => state.org);
    //force re-render when lng changes
    useSelector((state) => state.i18n.current);
    const selected_equipments_ids = useSelector((state) => state.dash.selected_equipments);
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.5
    });

    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current }); //maybe passing equiment_list ?
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current || !inView });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current || !inView });

    const measurementsOfEqt = useMemo(() => {
        if (dataflows.isSuccess && measurements.isSuccess && measurementtypes.isSuccess && inView) {
            //retrieve  dataflows for specific equipment
            const dataflows_list = _.chain(dataflows)
                .get("data", [])
                .filter({ equipment: equipment.id })
                .map((df) => df.id)
                .value();

            return _.chain(measurements)
                .get("data", [])
                .filter((measure) => _.includes(dataflows_list, measure?.dataflow))
                .reduce((res, measure) => {
                    const mt_type = _.find(measurementtypes.data, { id: measure?.measurementtype });
                    //Limit measurements here with blacklist
                    if (mt_type && !_.includes(mt_type_blacklist, mt_type?.name)) {
                        res.push(measure);
                    }
                    return res;
                }, [])
                .value();
        }
        return [];
    }, [equipment, dataflows, measurements, measurementtypes, inView]);

    const equipmentsMatching = useMemo(() => {
        if (equipments.isSuccess) {
            if (_.size(selected_equipments_ids) === 0) return true;
            const first_equipment = _.find(equipments.data, { id: _.head(selected_equipments_ids) });
            if (first_equipment === undefined) return false;
            //extract categories of first equipment
            const categories_of_first = first_equipment?.dataflowspec_set ?? [];
            if (_.size(categories_of_first) === 0) return false; // No categories => error ?
            const categories_of_equipment = equipment?.dataflowspec_set ?? [];
            if (_.size(categories_of_equipment) === 0) return false;
            return _.chain(categories_of_first).intersection(categories_of_equipment).size().value() > 0;
        }
        return true;
    }, [equipments, equipment, selected_equipments_ids]);

    return (
        <div ref={ref} style={{ minHeight: "150px", margin: 0 }} id={equipment?.name}>
            {measurements.isSuccess && measurementtypes.isSuccess && inView && isScrolling === false && (
                <Grid centered>
                    <Dimmer.Dimmable as={Grid.Row} dimmed={true} stretched>
                        <Dimmer inverted active={equipmentsMatching === false} style={{ zIndex: 2 }}>
                            <MessageDisplay
                                message={i18n._(t`The equipment must be of the same category as the other equipment selected`)}
                                level="warning"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Dimmer>
                        {/* COMPUTER DISPLAY */}
                        <Media greaterThanOrEqual="computer">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        <Grid.Column mobile={16} tablet={13} computer={13}>
                                            <Grid verticalAlign="top" centered padded="vertically">
                                                <Grid.Row>
                                                    <Grid.Column width={7}>
                                                        <Name equipment={equipment} />
                                                    </Grid.Column>
                                                    <Grid.Column width={2}>
                                                        <Details equipment={equipment} display="label" />
                                                    </Grid.Column>
                                                    <Grid.Column width={7} textAlign="right">
                                                        <Infos equipment={equipment} display="label" />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <DashboardWidgets equipment={equipment} measurements={measurementsOfEqt} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={3} computer={3}>
                                            <DispoInfo equipment={equipment} lastActivity={lastActivity} />
                                        </Grid.Column>
                                    </>
                                )
                            }
                        </Media>
                        {/* TABLET DISPLAY */}
                        <Media between={["tablet", "computer"]}>
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        <Grid.Column width={16}>
                                            <Grid verticalAlign="top" centered padded="vertically">
                                                <Grid.Row>
                                                    <Grid.Column width={11}>
                                                        <Name equipment={equipment} />
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <Details equipment={equipment} display="label" />
                                                        <Infos equipment={equipment} display="button" />
                                                    </Grid.Column>
                                                    <Grid.Column width={1}>
                                                        <DispoInfo equipment={equipment} display="button" lastActivity={lastActivity} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <DashboardWidgets equipment={equipment} measurements={measurementsOfEqt} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    </>
                                )
                            }
                        </Media>
                        {/* MOBILE DISPLAY */}
                        <Media lessThan="tablet">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <>
                                        <Grid.Column width={16}>
                                            <Grid verticalAlign="top" centered padded="vertically">
                                                <Grid.Row>
                                                    <Grid.Column width={10}>
                                                        <Name equipment={equipment} />
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        <Button.Group>
                                                            <Details equipment={equipment} display="button" />
                                                            <Infos equipment={equipment} display="button" />
                                                        </Button.Group>
                                                    </Grid.Column>
                                                    <Grid.Column width={2}>
                                                        <DispoInfo equipment={equipment} display="button" lastActivity={lastActivity} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <DashboardWidgets equipment={equipment} measurements={measurementsOfEqt} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Grid.Column>
                                    </>
                                )
                            }
                        </Media>
                    </Dimmer.Dimmable>
                </Grid>
            )}
        </div>
    );
};

export default React.memo(DashboardRow);
