import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal, Button, Icon, Grid, Divider } from "semantic-ui-react";
import { Form, Field } from "react-final-form";

import i18n from "modules/i18n/i18nConfig";
import { checkRights } from "modules/auth/utils";
import { removeAccents } from "modules/common/utils";
import { identity } from "modules/common/utils/form";
import { dialect_decimal_list, dialect_delimiter_list } from "modules/import/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import { typeImportOptions } from "../utils"; //typeImportOptions
import { useGetZonesQuery } from "modules/area/areaService";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetImportedDataSetsQuery, useAddImportedDataSetMutation, useUpdateImportedDataSetMutation } from "../importedDataSetsService";

import { InputAdapter, DropDownAdapter } from "modules/common/components/form";

const ImportedDataSetsModalForm = (props) => {
    const { item, setOpen } = props;

    const { org, auth } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const getImportedDataSets = useGetImportedDataSetsQuery({ org: org.current }, { skip: !org.current });

    const [createImportedDataset, create] = useAddImportedDataSetMutation();
    const [updateImportedDataset, update] = useUpdateImportedDataSetMutation();

    // create ImportedDataset
    useEffect(() => {
        if (create.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`Successful create imported dataset`), toast_options);
            setOpen(false);
        }
        if (create.isError) {
            toast.error(i18n._(t`Cannot add imported dataset. ${create.error.data?.non_field_errors?.[0] ?? ""}`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [create.isSuccess, create.isError]);

    // update ImportedDataset
    useEffect(() => {
        if (update.isLoading) {
            toast.info(i18n._(t`send request to server`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`Successful update imported dataset`), toast_options);
            setOpen(false);
        }
        if (update.isError) {
            toast.error(i18n._(t`Cannot update imported dataset`), toast_options_err);
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update.isLoading, update.isSuccess, update.isError]);

    const initialValues = useMemo(() => {
        const defaultValues = { name: "", zone: "", type_import: "", csv_separator: "", decimal_separator: "" };
        return {
            ...defaultValues,
            ...item
        };
    }, [item]);

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = i18n._(t`Required field`);
        }
        const existing_name = _.chain(getImportedDataSets.data)
            .filter((dataset) => {
                if (item === undefined) return true;
                return dataset.id !== parseInt(item.id);
            })
            .find({ name: values.name })
            .value();
        if (existing_name) {
            errors.name = i18n._(t`An dataset with this name exists`);
        }
        if (!values.zone) {
            errors.zone = i18n._(t`Required field`);
        } else {
            const zone = _.find(zones.data, { id: values.zone });
            const site = _.find(sites.data, { id: zone?.site_id });
            const rights = checkRights(site, auth.rights, true);
            if (!_.includes(rights, "can_change")) {
                errors.zone = i18n._(t`insufficient site rights`);
            }
        }
        if (!values.type_import) {
            errors.type_import = i18n._(t`Required field`);
        } else {
            const existing_type = _.chain(getImportedDataSets.data)
                .filter((dataset) => {
                    if (item === undefined) return true;
                    return dataset.id !== parseInt(item.id);
                })
                .find({ type_import: values?.type_import })
                .value();
            if (existing_type) {
                errors.name = i18n._(t`An dataset with this type exists`);
            }
        }
        if (!values.csv_separator) {
            errors.csv_separator = i18n._(t`Required field`);
        }
        if (!values.decimal_separator) {
            errors.decimal_separator = i18n._(t`Required field`);
        }
        return errors;
    };

    const submitForm = async (formData) => {
        if (item) {
            await updateImportedDataset({ org: org.current, data: formData });
        } else {
            const data = {
                ...formData,
                zone_name: _.find(zones, { id: formData.zone })?.name ?? "",
                owner: auth.user.user_id,
                owner_email: auth.user.email
            };
            await createImportedDataset({ org: org.current, data });
        }
    };

    const zoneOptions = useMemo(() => {
        if (zones.isSuccess) {
            return _.chain(zones.data)
                .map(({ key, text, value }) => ({ key, text, value }))
                .orderBy((item) => {
                    return removeAccents(item.text).toLowerCase();
                }, "asc")
                .value();
        }
        return [];
    }, [zones]);

    const typeImportDropdownOptions = useMemo(() => {
        return _.chain(typeImportOptions)
            .map(({ key, text, value }) => ({ key, text: i18n._(text), value }))
            .orderBy((item) => {
                return removeAccents(item.text).toLowerCase();
            }, "asc")
            .value();
    }, []);

    const dialect_decimal_list_options = useMemo(() => {
        return _.chain(dialect_decimal_list)
            .map(({ key, text, value }) => ({ key, text, value }))
            .value();
    }, []);

    const dialect_delimiter_list_options = useMemo(() => {
        return _.chain(dialect_delimiter_list)
            .map(({ key, text, value }) => ({ key, text, value }))
            .value();
    }, []);

    return (
        <Modal centered={false} closeOnDimmerClick={false} onClose={() => setOpen(false)} open={true}>
            <Modal.Header>
                {item?.id !== undefined && <Trans>Edit imported dataset</Trans>}
                {item?.id === undefined && <Trans>Add imported dataset</Trans>}
            </Modal.Header>
            <Modal.Content>
                <Form
                    onSubmit={submitForm}
                    initialValues={initialValues}
                    validate={validate}
                    render={({ handleSubmit, submitting, pristine, invalid, form }) => {
                        return (
                            <form onSubmit={handleSubmit} className="ui form">
                                <Grid verticalAlign="top" centered>
                                    <Grid.Column width={16}>
                                        <Field
                                            name="name"
                                            placeholder={i18n._(t`enter dataset name`)}
                                            label={i18n._(t`name`)}
                                            isRequired={true}
                                            parse={identity}
                                            component={InputAdapter}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Field
                                            name="zone"
                                            label={i18n._(t`zone`)}
                                            placeholder={i18n._(t`select zone`)}
                                            options={zoneOptions}
                                            component={DropDownAdapter}
                                            isRequired={true}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Field
                                            name="type_import"
                                            label={i18n._(t`Data type`)}
                                            placeholder={i18n._(t`select type of data`)}
                                            options={typeImportDropdownOptions}
                                            component={DropDownAdapter}
                                            isRequired={true}
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={8} tablet={16}>
                                        <Field
                                            name="decimal_separator"
                                            label={i18n._(t`Decimal separator`)}
                                            placeholder={i18n._(t`select decimal separator`)}
                                            isRequired={true}
                                            options={dialect_decimal_list_options}
                                            component={DropDownAdapter}
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={8} tablet={16}>
                                        <Field
                                            name="csv_separator"
                                            label={i18n._(t`Field separator`)}
                                            placeholder={i18n._(t`select field separator`)}
                                            isRequired={true}
                                            options={dialect_delimiter_list_options}
                                            component={DropDownAdapter}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={16}>
                                        <Divider />
                                    </Grid.Column>
                                    <Grid.Column width={16} textAlign="right">
                                        <Button type="button" negative onClick={() => setOpen(false)}>
                                            <Trans>cancel</Trans>
                                        </Button>
                                        <Button type="submit" positive icon labelPosition="right" disabled={submitting || pristine || invalid}>
                                            <Icon name="check" />
                                            <Trans>validate</Trans>
                                        </Button>
                                    </Grid.Column>
                                </Grid>
                            </form>
                        );
                    }}
                />
            </Modal.Content>
        </Modal>
    );
};

export default ImportedDataSetsModalForm;
